import React from 'react';

import eyecatcher from "../../assets/images/dokumente.jpg";
import miaMakingOfVideo from "../../assets/videos/mia.mp4";
import miaMakingOfVideoPart2 from "../../assets/videos/mia2.mp4";
import miaMakingOfVideoPart3 from "../../assets/videos/mia3.mp4";
import miaImportTeilnehmnerliste from "../../assets/videos/mia_Teilnehmerimport_neu.mp4"
import miaFinancing from "../../assets/videos/mia_financing.mp4"
import miaDynamicForms from "../../assets/videos/mia_dynamic_forms.mp4"
import eMSAnalysisVideo from "../../assets/videos/ems_analysis_clip.mp4";
import eMSExcelVideo from "../../assets/videos/ems_csv_clip.mp4";
import eMSDublicated from "../../assets/videos/eMS_duplicated_invoice_check.mp4";
import pietVideo from "../../assets/videos/PIET.mp4";
import schoene_Berichte_gif from "../../assets/gifs/schoene_berichte_versuch3.gif";
import eMSReportingAndAnalysis from "../../assets/pdfs/eMSReportingAndAnalysis.pdf";
import aboutTheEms20190318_beta from "../../assets/pdfs/aboutTheEms20190318_beta.pdf";
import eMS_implementing_programme_specific_requirements from "../../assets/pdfs/eMS_implementing_programme_specific_requirements.pdf";
import bifc_20120703_entwurf_version_1_1 from "../../assets/pdfs/bifc_20120703_entwurf_version_1_1.pdf";
import ecohesion_20120703_entwurf_version_1_0 from "../../assets/pdfs/ecohesion_20120703_entwurf_version_1_0.pdf";
import ecohesion_20120703_entwurf_version_1_1_eng from "../../assets/pdfs/ecohesion_20120703_entwurf_version_1_1_eng.pdf";
import piet_de from "../../assets/pdfs/piet_de.pdf";
import piet_en from "../../assets/pdfs/piet_eng.pdf";


import { FormattedMessage } from 'react-intl';

class Information extends React.Component {

    constructor(props) {
        super(props);

        // Methoden binden
        this.videoClicked = this.videoClicked.bind(this);
    }

    // Piwik Url und Title
    componentDidMount() {
        window._paq.push(['setCustomUrl', "/information"]);
        window._paq.push(['setDocumentTitle', "information"]);
        window._paq.push(['trackPageView']);
    }

    // Piwik Video
    videoClicked(nameVideo) {
        window._paq.push(['setDocumentTitle', nameVideo]);
        window._paq.push(['trackPageView']);
    }

    render() {
        document.title = "Information";
        return (
            <div id="content-main" className="container col-md-8 col-md-offset-2 ">
                <div className="row">
                    <div className="col-md-12 hidden-xs ">
                        <img className="eyecatcher" src={eyecatcher} style={{ width: "100%" }} alt="Bild" height="400"/>
                    </div>
                    {/* Auf Wunsch von Christian Mobile picture disabled
                    <div className="col-xs-12 visible-xs">
                        <img src={eyecatcher} style={{ width: "100%" }} alt="Bild" />
                    </div> */}
                </div>


                <div className="rwdokumente col-xs-offset-1">
                    <h3 style={{}}><FormattedMessage id="information.videos"></FormattedMessage></h3>
                </div>

                <div className="row">
                    <div className="col-md-8 col-md-offset-3 col-xs-10 col-xs-offset-1">
                        <p><b> mia - Financing </b> </p>
                        
                        <video id="MiaFinancing" controls className="emsVideo" onPlay={(event) => { this.videoClicked("VIDEO => MIA_FINANCING") }}>
                        <source src={miaFinancing} type="video/mp4" />
                        </video>

                        <p><b> mia - Dynamic Forms </b> </p>
                        
                        <video id="MiaDynamicForms" controls className="emsVideo" onPlay={(event) => { this.videoClicked("VIDEO => MIA_DYNAMIC_FORMS") }}>
                        <source src={miaDynamicForms} type="video/mp4" />
                        </video>

                        <p><b> eMS Excel Upload </b></p>
                        <iframe width="810" height="456" src="https://www.youtube.com/embed/rhZRd5Mfsk8" title="eMS Excel Upload" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>

                        <p><b> mia - Import Teilnehmerliste </b> </p>
                        
                        <video id="MiaImportTeilnehmerliste" controls className="emsVideo" onPlay={(event) => { this.videoClicked("VIDEO => MIA_TEILNEHMERLISTE") }}>
                        <source src={miaImportTeilnehmnerliste} type="video/mp4" />
                        </video>

                        <p><b> mia - making of part 3 </b> </p>
                    
                        <video id="MiaMakingOfVideo3" controls className="emsVideo" onPlay={(event) => { this.videoClicked("VIDEO => MIA_MAKING_OF3") }}>
                        <source src={miaMakingOfVideoPart3} type="video/mp4" />
                        </video>

                        <p><b> mia - making of part 2 </b> </p>

                        <video id="MiaMakingOfVideo2" controls className="emsVideo" onPlay={(event) => { this.videoClicked("VIDEO => MIA_MAKING_OF2") }}>
                            <source src={miaMakingOfVideoPart2} type="video/mp4" />
                        </video>

                        <p><b> mia - making of part 1 </b> </p>

                        <video id="MiaMakingOfVideo" controls className="emsVideo" onPlay={(event) => { this.videoClicked("VIDEO => MIA_MAKING_OF") }}>
                            <source src={miaMakingOfVideo} type="video/mp4" />
                        </video>

                        <p><b> eMS Analysis </b> </p>

                        <video id="EMSAnalysisVideo" controls className="emsVideo" onPlay={(event) => { this.videoClicked("VIDEO => EMS_Analysis_video") }}>
                            <source src={eMSAnalysisVideo} type="video/mp4" />
                        </video>

                        {/* eMS Excel Upload raus
                        <p><b> eMS Excel Upload </b></p>
                        <video id="EMSExelVideo" controls className="emsVideo" onPlay={(event) => { this.videoClicked("VIDEO => EMS_Excel_video") }}>
                            <source src={eMSExcelVideo} type="video/mp4" />
                        </video>
                        */}

                        <p><b> eMS Duplicated Invoice Check  </b></p>
                        <video id="EMSDuplicatedInvoiceCheck" controls className="emsVideo" onPlay={(event) => { this.videoClicked("VIDEO => EMS_DuplicatedInvoice_video") }}>
                            <source src={eMSDublicated} type="video/mp4" />
                        </video>

                        <p><b> PIET - Portal For Integrated e-Cohesion Technologies</b></p>
                        <video id="PietVideo" controls className="emsVideo" onPlay={(event) => { this.videoClicked("VIDEO => PIET_Portal_video") }}>
                            <source src={pietVideo} type="video/mp4" />
                        </video>
                    </div>
                </div>


                <div className="rwdokumente col-xs-offset-1">
                    {/* <h3><FormattedMessage id="information.information"></FormattedMessage></h3> */}
                    <h3><FormattedMessage id="information.artikel"></FormattedMessage></h3>
                </div>

                <div className="row">
                    <div className="col-md-8 col-md-offset-3 col-xs-10 col-xs-offset-1">

                        {/* <p><b> <FormattedMessage id="information.artikel"></FormattedMessage> </b> </p> */}
                        <p><a onClick={(event) => { this.videoClicked("ARTIKEL => eMS: Reporting and Analysis"); window.open(eMSReportingAndAnalysis, "_blank")}}> eMS: Reporting and Analysis</a></p>
                        <p><a onClick={(event) => { this.videoClicked("ARTIKEL => eMS: About the eMS"); window.open(aboutTheEms20190318_beta, "_blank")}}> eMS: About the eMS</a></p>
                        <p><a onClick={(event) => { this.videoClicked("ARTIKEL => eMS: Implementing Programme Specific Requirements"); window.open(eMS_implementing_programme_specific_requirements, "_blank")}}> eMS: Implementing Programme Specific Requirements </a></p>
                        {/* <p><a href="http://www.rechenwerk.com/pdfviewer/web/viewer.html?file=kostencontrolling-in-projektorientierten-dienstleistungsunternehmen.pdf"> Kostencontrolling in projektorientierten Dienstleistungsunternehmen </a></p> */}
                        <p><a onClick={(event) => { this.videoClicked("ARTIKEL => Der Einsatz von Business Intelligence im Fördermittelmanagement"); window.open(bifc_20120703_entwurf_version_1_1, "_blank")}}>Der Einsatz von Business Intelligence im Fördermittelmanagement </a></p>
                        <p><a onClick={(event) => { this.videoClicked("ARTIKEL => e-Cohesion - Analyse der Vorgaben und Skizzierung möglicher Lösungsideen"); window.open(ecohesion_20120703_entwurf_version_1_0, "_blank")}}>e-Cohesion - Analyse der Vorgaben und Skizzierung möglicher Lösungsideen</a></p>
                        <p><a onClick={(event) => { this.videoClicked("ARTIKEL => e-Cohesion - Analysis of requirements and outlining of possible solution concepts"); window.open(ecohesion_20120703_entwurf_version_1_1_eng, "_blank")}}> e-Cohesion - Analysis of requirements and outlining of possible solution concepts </a></p>

                        {/* <p><b> <FormattedMessage id="information.präsentation"></FormattedMessage> </b> </p> */}
                        {/* <p className="textcolor"><a href="http://www.rechenwerk.com/pdfviewer/web/viewer.html?file=piet_de.pdf"> PIET - Portal für integrierte e-Cohesion Technologien (deutsch) </a></p>
                        <p><a href="http://www.rechenwerk.com/pdfviewer/web/viewer.html?file=piet_eng.pdf"> PIET - Portal for integrated e-cohesion technologies (english) </a></p> */}


                    </div>
                </div>

                <div className="rwdokumente col-xs-offset-1">
                    <h3><FormattedMessage id="information.gifs"></FormattedMessage></h3>
                </div>

                <div className="row">
                    <div className="col-md-8 col-md-offset-3 col-xs-10 col-xs-offset-1">
                        <p><b> Report Designs</b> </p>
                        <img id="fund_gif" className="gifs" src={schoene_Berichte_gif} alt="report designs" />
                    </div>
                </div>

                


                <div className="rwdokumente col-xs-offset-1">
                    <h3> <FormattedMessage id="information.präsentation"></FormattedMessage> </h3>
                </div>

                <div className="row">
                    <div className="col-md-8 col-md-offset-3 col-xs-10 col-xs-offset-1">
                        <p className="textcolor"><a onClick={(event) => { this.videoClicked("ARTIKEL => PIET - Portal für integrierte e-Cohesion Technologien (deutsch)"); window.open(piet_de, "_blank")}}> PIET - Portal für integrierte e-Cohesion Technologien (deutsch) </a></p>
                        <p><a onClick={(event) => { this.videoClicked("ARTIKEL => PIET - Portal für integrierte e-Cohesion Technologien (english)"); window.open(piet_en, "_blank")}}> PIET - Portal for integrated e-cohesion technologies (english) </a></p>
                    </div>
                </div>
            </div >
        )
    }
}

export default Information;